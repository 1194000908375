<template>
  <div class="contact">
    <div class="container-fluid py-4">
      <h1 class="h5 fw-bold mb-3">{{ $t("__contactUs") }}</h1>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="mb-3">
              <label for="type" class="form-label fw-bold">{{
                $t("__issueCategory")
              }}</label>
              <div class="col-sm-6 col-md-4">
                <select
                  class="form-select"
                  id="type"
                  v-model="form.type"
                  required
                >
                  <option value="" selected>
                    {{ $t("__openThisSelectMenu") }}
                  </option>
                  <option value="1">{{ $t("__gettingOutOfBed") }}</option>
                  <option value="2">{{ $t("__sleep") }}</option>
                  <option value="3">{{ $t("__breathe") }}</option>
                  <option value="4">{{ $t("__restlessness") }}</option>
                  <option value="5">{{ $t("__longInBed") }}</option>
                  <option value="6">{{ $t("__network") }}</option>
                  <option value="7">{{ $t("__other") }}</option>
                </select>
              </div>
            </div>
            <div class="mb-3">
              <label for="description" class="form-label fw-bold">{{
                $t("__description")
              }}</label>
              <div class="col-sm-6 col-md-4">
                <textarea
                  class="form-control"
                  id="description"
                  v-model="form.description"
                  rows="3"
                  required
                ></textarea>
              </div>
            </div>
            <button type="submit" class="btn btn-primary rounded-pill">
              {{ $t("__submit") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { agency } from '@/http/api/agency.js'
import i18n from '@/lang/lang.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'contact',
  data () {
    return {
      form: {
        type: '',
        description: null
      }
    }
  },
  computed: {
    ...mapState(['token'])
  },
  watch: {},
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    resetForm () {
      this.form = {
        type: '',
        description: null
      }
    },
    submit () {
      const vm = this
      agency.contact(vm.form, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.resetForm()
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__submitSuccess')
          })
          vm.Alerted()
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    }
  },
  created () {},
  mounted () {}
}
</script>

<style  lang="scss">
.contact {
  .card {
    position: relative;
    &-body {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("~@/assets/images/icon.svg");
      background-repeat: no-repeat;
      background-position: calc(100% + 20px) calc(100% + 20px);
      background-size: auto;
      opacity: 0.1;
      z-index: 0;
    }
  }
}
</style>
